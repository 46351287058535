"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { FloatingNav } from "@/components/ui/floating-navbar";
import { navData } from "@/constants/nav-data";
import { cn } from "@/lib/utils";

export default function Header() {
  return (
    <header className="relative mx-auto w-full px-4 md:px-6 lg:px-8">
      <FloatingNav navItems={navData} className="max-w-4xl" />
    </header>
  );
}

export function NavItem({
  href,
  label,
  onClick,
}: {
  href: string;
  label: string;
  onClick?: () => void;
}) {
  const pathname = usePathname();

  return (
    <Link
      href={href}
      className={cn(
        "py-3 transition-colors hover:text-foreground/80",
        pathname?.startsWith(href) ? "text-foreground" : "text-foreground/60",
      )}
      onClick={onClick}
    >
      {label}
    </Link>
  );
}
