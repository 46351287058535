"use client";

import type { TNavItem } from "@/@types";
import React, { useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { logoSvg } from "@/constants/assets";
import { useWaitListStore } from "@/store/use-whaitlist.store";
import {
  AnimatePresence,
  motion,
  useMotionValueEvent,
  useScroll,
} from "framer-motion";

import { cn } from "@acme/ui/lib/utils";

import ThemeToggle from "../theme-toggle";
import MobileNav from "../view-layout/mobile-navbar";

export const FloatingNav = ({
  navItems,
  className,
}: {
  navItems: {
    name: string;
    link: string;
    icon?: JSX.Element;
  }[];
  className?: string;
}) => {
  const { scrollYProgress } = useScroll();

  const [visible, setVisible] = useState(true);

  const { setShowModal } = useWaitListStore();

  useMotionValueEvent(scrollYProgress, "change", (current) => {
    // Check if current is not undefined and is a number
    if (typeof current === "number") {
      const direction = current - scrollYProgress.getPrevious();

      if (scrollYProgress.get() < 0) {
        setVisible(false);
      } else {
        if (direction < 0) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      }
    }
  });

  return (
    <AnimatePresence mode="wait">
      <motion.div
        initial={{
          opacity: 1,
          y: -100,
        }}
        animate={{
          y: visible ? 0 : -100,
          opacity: visible ? 1 : 0,
        }}
        transition={{
          duration: 0.2,
        }}
        className={cn(
          "fixed inset-x-0 top-3 z-[5000] mx-auto flex max-w-fit items-center justify-between rounded-full border border-transparent bg-white py-2 pl-8 pr-2 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] dark:border-white/[0.2] dark:bg-black",
          className,
        )}
      >
        <Link className="mr-6 space-x-2 lg:flex" href="/">
          <Image
            src={logoSvg}
            width={400}
            height={400}
            alt="Logo"
            className="h-6 w-6"
          />
          <span className="hidden text-lg font-bold text-primary md:block dark:text-zinc-300">
            CoHub42
          </span>
        </Link>

        {/* <div className="flex items-center space-x-4">
          {navItems.map((navItem: TNavItem, idx: number) => (
            <Link
              key={idx}
              href={navItem.link}
              className={cn(
                "relative flex items-center space-x-1 text-neutral-600 hover:text-neutral-500 dark:text-neutral-50 dark:hover:text-neutral-300",
              )}
            >
              <span className="hidden text-sm sm:block">{navItem.name}</span>
            </Link>
          ))}
        </div> */}

        <div className="flex items-center space-x-2">
          <button
            className="relative rounded-full border border-neutral-200 bg-primary px-4 py-2 text-sm font-medium text-white dark:border-white/[0.2]"
            onClick={() => setShowModal(true)}
          >
            <span>Get Started</span>
            <span className="absolute inset-x-0 -bottom-px mx-auto h-px w-1/2 bg-gradient-to-r from-transparent via-blue-500 to-transparent" />
          </button>
          {/* <MobileNav /> */}
          <ThemeToggle />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
