"use client";

import React from "react";
import { useWaitListStore } from "@/store/use-whaitlist.store";
import { api } from "@/trpc/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { toast } from "sonner";
import { z } from "zod";

import { Icons } from "@acme/ui/components/icons";
import { Button } from "@acme/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
} from "@acme/ui/components/ui/card";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "@acme/ui/components/ui/drawer";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@acme/ui/components/ui/form";
import { Input } from "@acme/ui/components/ui/input";
import { PhoneInput } from "@acme/ui/components/ui/phone-input";

export const formSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  phoneNumber: z
    .string()
    .refine(isValidPhoneNumber, { message: "Invalid phone number" }),
});

export default function RequestWaitListDrawer() {
  const { showModal, setShowModal, onCloseModal } = useWaitListStore();

  const { mutate, isPending: isSubmitting } = api.waitlist.create.useMutation({
    onSuccess: () => {
      console.log("success");
      onCloseModal();
      toast.success("Success", {
        description: "You have been added to the waitlist",
      });
      form.resetField("firstName");
      form.resetField("lastName");
      form.resetField("email");
      form.resetField("phoneNumber");
    },
    onError: (error) => {
      console.error("error", error);
      toast.error("Error", {
        description:
          error?.message ??
          "An error occurred while adding you to the waitlist",
      });
    },
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    console.log(values);
    mutate(values);
  }

  return (
    <Drawer open={showModal} onOpenChange={(open) => setShowModal(open)}>
      <DrawerContent className="">
        <div className="mx-auto w-full max-w-sm">
          <DrawerHeader>
            <div className="mb-5 space-y-2 text-center">
              <CardTitle>Join the Waitlist</CardTitle>
              <CardDescription>
                Fill out the form to be added to the waitlist.
              </CardDescription>
            </div>
          </DrawerHeader>

          <Card className="mx-auto w-full max-w-lg">
            <CardContent className="space-y-4">
              <Form {...form}>
                <form className="mt-2 space-y-8">
                  <FormField
                    control={form.control}
                    name="firstName"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>First Name</FormLabel>
                        <FormControl>
                          <Input
                            id="firstName"
                            placeholder="Enter your first name"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="lastName"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Last Name</FormLabel>
                        <FormControl>
                          <Input
                            id="lastName"
                            placeholder="Enter your last name"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          <Input
                            id="email"
                            placeholder="Enter your email address"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Phone number</FormLabel>
                        <FormControl>
                          <PhoneInput
                            defaultCountry="AE"
                            id="phoneNumber"
                            placeholder="Enter your phone number"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </form>
              </Form>
            </CardContent>
          </Card>

          <DrawerFooter>
            <Button onClick={form.handleSubmit(onSubmit)} className="w-full">
              {isSubmitting ? (
                <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <Icons.arrowRight className="mr-2 h-4 w-4" />
              )}{" "}
              Join Waitlist
            </Button>
            <DrawerClose asChild>
              <Button variant="outline">Cancel</Button>
            </DrawerClose>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
