import { Icons } from "@acme/ui/components/icons";

export const navData = [
  {
    name: "Home",
    link: "/#Home",
    icon: (
      <Icons.facebook className="h-4 w-4 text-neutral-500 dark:text-white" />
    ),
  },
  {
    name: "Refer",
    link: "/#Refer",
    icon: (
      <Icons.facebook className="h-4 w-4 text-neutral-500 dark:text-white" />
    ),
  },
  {
    name: "Network",
    link: "/#Network",
    icon: (
      <Icons.facebook className="h-4 w-4 text-neutral-500 dark:text-white" />
    ),
  },
  {
    name: "Insights",
    link: "/#Insights",
    icon: (
      <Icons.facebook className="h-4 w-4 text-neutral-500 dark:text-white" />
    ),
  },
];
